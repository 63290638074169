<template>
  <NavBar/>

  <main style="background-color: #eff2f6;">
      <div class="container">
          <div class="row">
              <div class="col-md-4">
                  <ProfilCard/>
              </div>
              <div class="col-12 col-md-8">
                <section class="col-12">
                  <h2 class="text-center">
                    {{ $t('personnal-projects') }}
                  </h2>
                  
                  <ProjectList
                    :type="'Personnal'"/>
                  
                  <h2 class="text-center">
                    {{ $t('academic-projects') }}
                  </h2>
                  
                  <ProjectList
                    :type="'Academic'"/>
                </section>
              </div>
          </div>
      </div>
  </main>
</template>

<script>
import ProfilCard from './components/cv/ProfilCard.vue';
import NavBar from './components/global/NavBar.vue';
import ProjectList from './components/projects/ProjectList.vue';
import Constants from './ts/constants.ts';

export default {
  name: 'App',  
  created: function() {
    document.title = Constants.TITLE;
  },
  components: {
    ProjectList,
    NavBar,
    ProfilCard
  }
}
</script>
