<template>
    <div class="col-12 col-md-6 p-2">
      <div class="card">
        <div class="card-body">
          <h3>{{ title }}</h3>
          <p>
            {{ description }}
          </p>
          
          <LinkIcon 
            :link="link"
            :linkType="linkType"
            :cssClass="'card-link text-decoration-none'"
            :title="'Access to my project ' + title"/>
        </div>
      </div>
    </div>
</template>

<script>
import LinkIcon from '../global/LinkIcon.vue';

export default{
    props: {
        title: String,
        description: String,
        link: String,
        linkType: String
    },
    components: { LinkIcon }
}
</script>