var ProjectService = /** @class */ (function () {
    function ProjectService() {
    }
    ProjectService.prototype.getProjects = function () {
        return [
            {
                title: 'Alex-développeur',
                description: {
                    'en': 'This is my personnal website developed in Vue.js and TypeScript.',
                    'fr': 'Ceci est mon site personnel développé en Vue.js et TypeScript.'
                },
                url: { link: 'https://www.alex-developpeur.fr/', type: "Website" /* UrlType.Website */ }, type: "Personnal" /* ProjectType.Personnal */
            },
            {
                title: 'Tomel',
                description: {
                    'en': 'Academic project which is developed with students in Vue.js and TypeScript.',
                    'fr': 'Projet académique développé avec des étudiants en Vue.js et TypeScript.'
                },
                url: { link: 'https://tomel.alex-developpeur.fr/', type: "Website" /* UrlType.Website */ }, type: "Personnal" /* ProjectType.Personnal */
            },
            {
                title: 'Tutorial SCSS',
                description: {
                    'en': 'Sample repository to present and introduce SCSS (variables, mixins..) to students.',
                    'fr': 'Exemple de projet pour présenter et introduire SCSS (variables, mixins..) aux étudiants.'
                },
                url: { link: 'https://github.com/lelexxx/cours-scss', type: "Github" /* UrlType.Github */ }, type: "Academic" /* ProjectType.Academic */
            },
            {
                title: 'Tutorial TS',
                description: {
                    'en': 'Sample repository to present and introduce TypeScript (class, interface, get/set...) to students.',
                    'fr': 'Exemple de projet pour présenter et introduire TypeScript (classe, interface, get/set...) aux étudiants.'
                },
                url: { link: 'https://github.com/lelexxx/cours-typescript', type: "Github" /* UrlType.Github */ }, type: "Academic" /* ProjectType.Academic */
            },
            {
                title: 'Firestore',
                description: {
                    'en': 'Sample repository to present and introduce Firestore environment (collection, converter, promise...) to students.',
                    'fr': 'Exemple de projet pour présenter et introduire l\'environnement Firestore (collection, convertisseur, promesse...) aux étudiants.'
                },
                url: { link: 'https://github.com/lelexxx/cours-firestore', type: "Github" /* UrlType.Github */ }, type: "Academic" /* ProjectType.Academic */
            },
            {
                title: 'LocalStorage',
                description: {
                    'en': 'Sample repository to present and introduce LocalStorage to students.',
                    'fr': 'Exemple de projet pour présenter et introduire le LocalStorage aux étudiants.'
                },
                url: { link: 'https://github.com/lelexxx/cours-local-storage', type: "Github" /* UrlType.Github */ }, type: "Academic" /* ProjectType.Academic */
            },
        ];
    };
    ProjectService.prototype.getProjectsByType = function (type) {
        return this.getProjects().filter(function (p) { return p.type === type; });
    };
    return ProjectService;
}());
export default ProjectService;
