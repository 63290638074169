<template>
    <li>
        <LinkIcon 
            :link="link"
            :linkType="linkType"
            cssClass="dropdown-item"/>
    </li>
</template>

<script>
import LinkIcon from '../global/LinkIcon.vue';

export default{
    props: {
        link: String,
        linkType: String
    },
    components: { LinkIcon }
}
</script>