<template>
    <nav class="navbar navbar-expand-lg">
        <div class="container">    
            <ul class="navbar-nav navbar-nav-scroll ms-auto">

                <li class="nav-item dropdown">      
                    <a class="nav-link dropdown-toggle" href="#" :title="$t('languages')" :aria-label="$t('languages')" id="languageSelector" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ $t('languages') }}
                    </a>              
                    <ul class="dropdown-menu" aria-labelledby="languageSelector">
                        <li v-for="(lang, i) in langs" :key="`Lang${i}`">
                            <a class="dropdown-item" href="#" :title="lang.text" :lang="lang.code" @click="changeLocale(lang.code)">
                                {{ lang.text }}
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" :title="$t('social-medias-title')" :aria-label="$t('social-medias-title')" id="socialMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ $t('social-medias') }}
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="socialMenu">
                        <SocialMediaItem 
                            v-for="socialMedia in socialMedias" 
                            :key="socialMedia.type"
                            :link="socialMedia.link"
                            :linkType="socialMedia.type"/>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import i18n from "@/i18n";
import SocialMediaService from '../../ts/services/social.service.ts';
import SocialMediaItem from '../cv/SocialMediaItem.vue';

export default {
    data() {
        const socialMediaService = new SocialMediaService();
        return {
            socialMedias: socialMediaService.getSocialMedias(),
            langs: [
                { code: "en", text: "English" },
                { code: "fr", text: "Français" },
            ]
        };
    },
    components: { SocialMediaItem },
    methods: {
        changeLocale: function(locale){
            document.querySelector("html").setAttribute("lang", locale);
            i18n.global.locale.value = locale;
        }
    }
}
</script>