<template>
    <a :href="link" target="_blank" :title="computedTitle" :class="cssClass">
        <font-awesome-icon :icon="linkCssClass" />&nbsp;
        {{ linkLabel }}
    </a>
</template>

<script>
import SocialMediaService from '../../ts/services/social.service.ts';

export default{
    props: {
        link: String,
        linkType: String,
        cssClass: String,
        displayText: Boolean,
        title: String
    },
    computed:{
        linkLabel: function () {
            const socialMediaService = new SocialMediaService();

            return socialMediaService.linkLabel(this.linkType);
        },
        linkCssClass: function () {
            const socialMediaService = new SocialMediaService();

            return socialMediaService.linkCssClass(this.linkType);
        },
        computedTitle: function(){
            if(!this.title)
                return `Find me on ${this.linkLabel}`;

            return this.title;
        }
    }
}
</script>