var SocialMediaService = /** @class */ (function () {
    function SocialMediaService() {
    }
    SocialMediaService.prototype.getSocialMedias = function () {
        return [
            { link: 'https://twitter.com/l_lexxx', type: "Twitter" /* UrlType.Twitter */ },
            { link: 'https://discord.gg/Yk3tUG3', type: "Discord" /* UrlType.Discord */ },
            { link: 'https://www.linkedin.com/in/alexandre-ruiz-32428261/', type: "LinkedIn" /* UrlType.LinkedIn */ }
        ];
    };
    SocialMediaService.prototype.linkLabel = function (urlType) {
        return urlType.toString();
    };
    SocialMediaService.prototype.linkCssClass = function (urlType) {
        switch (urlType) {
            case "Website" /* UrlType.Website */:
                return 'fa fa-link';
            default:
                return 'fa-brands fa-' + urlType.toString().toLowerCase();
        }
    };
    return SocialMediaService;
}());
export default SocialMediaService;
