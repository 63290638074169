  <template>
    <div class="row">
      <ProjectCard 
        v-for="project in projects" 
        :key="project.title"
        :title="project.title"
        :description="project.description[$i18n.locale]"
        :link="project.url.link"
        :linkType="project.url.type"/>
    </div>
  </template>

<script>
import ProjectService from '../../ts/services/project.service.ts';
import ProjectCard from './ProjectCard.vue';

export default {
    props:{
      type: String
    },
    data() {
        const projectService = new ProjectService();
        
        return {
            projects: projectService.getProjectsByType(this.type)
        };
    },
    components: { ProjectCard }
}
</script>
