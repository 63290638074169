import { createApp } from 'vue';
import App from './App.vue';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faDiscord, faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import i18n from './i18n';

library.add(faTwitter, faGithub, faLinkedin, faDiscord, faLink);

createApp(App).use(i18n)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app');