<template>
    <nav class="navbar navbar-expand-lg mx-0">
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasSideNavbar" style="visibility: hidden;" aria-hidden="true">
            <div class="offcanvas-header">
                <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

            <div class="offcanvas-body d-block px-2 px-lg-0">
                <div class="card overflow-hidden">
                    <div class="card-body pt-0">
                        <div class="text-center">
                            <h1 class="h2 mb-0">Alexandre Ruiz</h1>
                        </div>
                        
                        <div class="text-center mb-3">
                            <small>{{ $t('job') }}</small>
                        </div>

                        <p>{{ $t('personnal-description') }} </p>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ProfilCard',
  setup() {
    const { t } = useI18n();

    return { t }
  }
})
</script>